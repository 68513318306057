import React from 'react';
import PropTypes from 'prop-types';
import styles from './vuture_embed.scss';

function VutureEmbed({
    embed_vuture,
    vuture_link,
}) {
    if (!embed_vuture || !vuture_link) {
        return null;
    }

    return (
        <iframe
            className={styles.vutureEmbed}
            src={vuture_link}
            frameBorder="0"
            scrolling="auto"
        />
    );
}

VutureEmbed.propTypes = {
    embed_vuture: PropTypes.bool,
    vuture_link: PropTypes.string,
};

VutureEmbed.defaultProps = {
    embed_vuture: false,
    vuture_link: '',
};

export default VutureEmbed;
